import { OptionInterface } from '/-/components/controls/setup'
import CamelToTitle from '/~/plugins/utils/camelToTitle'

type GetLocalFunctionType = (path: string, data?: any, count?: number) => string
type OptionMap = Record<number | string, string>

export function enumToOptions(enumObject: { [key: number | string] : number | string }, isNumeric = true, split = false): OptionInterface[] {
  return Object.keys(enumObject)
    .filter(key => isNumeric ? !isNaN(Number(enumObject[key as keyof typeof enumObject])) : key)
    .map(key => ({
      label: split ? CamelToTitle(key) : key,
      value: enumObject[key as keyof typeof enumObject],
    }))
}

export function translateOptions(options: readonly OptionInterface[], getLocal: GetLocalFunctionType, section: string): OptionInterface[] {
  return options.map(option => {
    return {
      value: option.value,
      label: getLocal(`${section}.${option.label}`)
    }
  })
}

export function optionsToMap(options: readonly OptionInterface[]) {
  return options.reduce((acc, { value, label }) => {
    acc[value] = label
    return acc
  }, {} as OptionMap)
}
