import { combineRegex } from '/~/plugins/utils/combineRegex'

export const requiredValidator = value => {
  return !!String(value || '').trim().length || 'Is required'
}

export const emailValidator = value => {
  return !value || /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}/.test(value) ? true : 'Must be a valid email'
}

export const urlValidator = value => {
  return !value || /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.%]+$/.test(value)
}

export const urlWithProtocolValidator = value => {
  return !value || /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.%]+$/.test(value)
}

export const notLinkValidator = value => {
  return !value || /^[^:/]*$/.test(value) ? true : 'This field should not contain a URL'
}

export const minLengthValidator = (value, params, ctx) => {
  const field = ctx.field
  const minLength = params[0]

  if (!minLength) {
    console.error(`Min length is not specified for min rule in ${field} field`)
    return false
  }
  return String(value || '').trim().length >= Number(minLength) || `This field should contain at least ${minLength} chars`
}

export const maxLengthValidator = (value, params, ctx) => {
  const field = ctx.field
  const maxLength = params[0]

  if (!maxLength) {
    console.error(`Max length is not specified for max rule in ${field} field`)
    return false
  }
  return String(value || '').trim().length <= Number(maxLength) || `This field should contain ${maxLength} chars max`
}

export const confirmedValidator = (value, params, ctx) => {
  const target = params[0]

  if (!target) {
    console.error('Please specify the name of the field to confirm. E.g. "confirmed:password"')
    return false
  }

  if (!(target in ctx.form)) {
    console.error(`Cannot confirm "${target}". The field does not exist in the form.`)
    return false
  } else {
    return String(value || '').trim() === String(ctx.form[target]).trim() || `Does not match with ${target}`
  }
}

export const lowercaseValidator = (value: string) => !value || value === value.toLowerCase() ? true : 'All letters should be in lower case'

export const TEMPLATE_VAR_REGEX = /{{\s?\$(\S+)\s?}}/g
const SINGLE_BRACKET = /(([^{]|^){\s?\$)|(\$\w+\s?}([^}]|$))/g
const EXTRA_BRACKETS = /({|}){3,}/g
const VAR_WITHOUT_DOLLAR = /{{[^$]*}}/g
const VAR_WITHOUT_BRACKETS = /((([^{]{2})|(^[^{])|^)\$\w+)|(\$(\w+\w\b)(([^}]$)|([^}]{2})|$))/g

const INVALID_VAR_FORMAT_ERROR = 'Invalid variable format. The variable should be in the format {{ $firstName }}'

const templateVarAndBracketRegex = combineRegex([SINGLE_BRACKET, EXTRA_BRACKETS, VAR_WITHOUT_DOLLAR, VAR_WITHOUT_BRACKETS], 'g')

export const templateValidator = (value: string, params: any[]) => {
  if (value.match(templateVarAndBracketRegex)) {
    return INVALID_VAR_FORMAT_ERROR
  }

  const allowedVariables = params[0] || []
  const unsupportedVariables: string[] = []
  const matches = [...value.matchAll(TEMPLATE_VAR_REGEX)]

  matches.forEach(data => {
    if (!allowedVariables.includes(data[1])) {
      unsupportedVariables.push(data[0])
    }
  })

  return unsupportedVariables.length ? `Template contains unsupported variables: ${unsupportedVariables.join(', ')}` : true
}
