import { createApp } from 'vue'
import App from './app.vue'
import router from './router'
import icons from '/-/plugins/icons'
import directives from '/-/directives'
import components from '/-/components'
import auth, { useAuth } from '/-/plugins/auth'
import { useAuthToken } from '/~/plugins/auth-token'
import api from '/-/plugins/api'
import locale from '/-/plugins/locale'
import colorPicker from '/-/plugins/color-picker'
import customIcons from './icons'
import languages from './languages'
import veeValidate from './plugins/vee-validate'
import './index.css'
import 'reflect-metadata'
import dayjs from '/~/plugins/dayjs'
import marked from '/~/plugins/marked'

const app = createApp(App)

async function refreshToken() {
  const { auth, refresh, logout } = useAuth()
  const { saveToken, removeToken } = useAuthToken()

  if (auth.value) {
    try {
      const token = await refresh()

      await saveToken(token)
    } catch (error) {
      await logout()
      removeToken()
      window.location.reload()
    }
  }
}

async function setAuth() {
  const { setToken } = useAuth()
  const { getToken } = useAuthToken()

  const token = await getToken()

  if (token) {
    setToken(token)
  }
}

app.use(icons, customIcons)
app.use(directives)
app.use(components)
app.use(colorPicker)
app.use(veeValidate)
app.use(locale, languages)
app.use(dayjs)
app.use(marked)

app.use(auth, {
  loginRoute: 'auth-login',
  router
})

app.use(api, {
  default: 'gateway-admin',
  resources: [
    {
      name: 'gateway',
      baseUrl: import.meta.env.VITE_GATEWAY_API,
      async headers() {
        const { auth } = useAuth()

        return {
          Authorization: auth && `Bearer ${auth.value?.accessToken}`,
        }
      },
      refreshToken
    },
    {
      name: 'gateway-admin',
      baseUrl: import.meta.env.VITE_GATEWAY_ADMIN_API,
      async headers() {
        const { auth } = useAuth()

        return {
          Authorization: auth && `Bearer ${auth.value?.accessToken}`,
        }
      },
      refreshToken
    },
    {
      name: 'gateway-oauth',
      baseUrl: import.meta.env.VITE_GATEWAY_API,
      async headers() {
        const { auth } = useAuth()

        return {
          Authorization: auth && `Bearer ${auth.value?.accessToken}`,
        }
      },
    },
  ],
})

setAuth().then(async () => {
  app.use(router)
  app.mount('#app')
})
