export default {
  PlatformRegistration: 'Platform registration (Welcome)',
  PasswordRecovery: 'Password recovery',
  JobApplication: 'Job application',
  EventRegistrationNewUser: 'Event registration (new user)',
  EventRegistrationExistingUser: 'Event registration (existing user)',
  ChatNewMessage: 'Chat new message',
  MeetingRequestSent: 'Meeting request sent',
  MeetingRequestAccepted: 'Meeting request accepted',
  MeetingRequestRejected: 'Meeting request rejected',
  MeetingCanceled: 'Meeting canceled',
  MeetingParticipantLeave: 'Meeting participant leave',
  MeetingPostponed: 'Meeting postponed',
}
