import { Expose, Transform } from 'class-transformer'
import { Media } from '/~/models/media'
import type { ConfigInterface } from '/-/models/config'
import { enumToOptions } from '/~/plugins/utils'
import { Currency } from '/~/models/currency'
export type { ConfigInterface, EventLocationInterface } from '/-/models/config'

export type EventIdType = number

export enum EventStatus {
  Draft = 'draft',
  Disabled = 'disabled',
  Open = 'open'
}

export enum EventType {
  Default,
  Course,
}

export const EventStatusOptions = Object.keys(EventStatus).map(key => (
  {
    label: key,
    value: EventStatus[key as keyof typeof EventStatus],
  }
))

export const EventTypeOptions = enumToOptions(EventType, true)

export enum EventSubtype {
  Conference,
  Connect,
  'CEO Summit',
  Other
}

export const EventSubtypeOptions = enumToOptions(EventSubtype, true)

export enum EventRegion {
  Europe,
  APAC,
  LATAM,
  MENA,
  NORAM,
  AFRICA,
  MEAST,
  CIS
}

export const EventRegionOptions = enumToOptions(EventRegion, true)

export class Event {
  id!: EventIdType
  slug!: string
  name!: string
  icon!: string
  color!: string
  type!: EventType
  city!: string | null
  tagline!: string | null

  @Expose({ name: 'linked_events_users' })
  linkedEventsUsers!: number[]

  @Expose({ name: 'linked_events_lectures' })
  linkedEventsLectures!: number[]

  @Expose({ name: 'date_from' })
  dateFrom!: string

  @Expose({ name: 'date_to' })
  dateTo!: string

  @Expose({ name: 'hub_date_from' })
  hubDateFrom!: string | null

  @Expose({ name: 'hub_date_to' })
  hubDateTo!: string | null

  @Expose({ name: 'custom_date' })
  @Transform(({ value }) => {
    const transformedValue = value || ''

    try {
      return JSON.parse(transformedValue)
    } catch (_error) {
      return transformedValue
    }
  }
  , { toClassOnly: true })
  @Transform(({ value }) => typeof value === 'string' ? value : JSON.stringify(value), { toPlainOnly: true })
  customDate!: string | Record<string, string>

  timezone!: string
  latitude?: number
  longitude?: number
  active = false
  status!: EventStatus
  config!: ConfigInterface
  host!: string
  media!: Media[]
  logo!: Media
  background!: Media

  @Expose({ name: 'is_show' })
  isShowInEventsList = false

  @Expose({ name: 'is_show_for_member' })
  isShowForMember = false

  @Expose({ name: 'is_online' })
  isOnline = false

  @Expose({ name: 'is_onsite' })
  isOnsite = false

  @Expose({ name: 'auto_reg' })
  autoRegTicketId!: number

  address!: string

  @Expose({ name: 'address_link' })
  addressLink!: string

  description!: string

  @Expose({ name: 'preview_description' })
  previewDescription!: string

  @Expose({ name: 'event_type' })
  subtype!: EventSubtype | null

  @Expose({ name: 'main_currency' })
  currency!: null | Currency

  @Expose({ name: 'mail_from_email' })
  mailFromEmail!: null | string

  @Expose({ name: 'mail_from_name' })
  mailFromName!: null | string

  regions: EventRegion[] = []

  get currencyDefault() {
    return Currency.EUR
  }

  get mailFromNameDefault() {
    return this.type === EventType.Course ? 'WN Academy' : this.name
  }

  get mailFromEmailDefault() {
    return this.type === EventType.Course ? 'academy@wn.media' : 'noreply@wn.media'
  }
}
