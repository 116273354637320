export default {
  disableEmailNotifications: 'Disable email notifications',
  newPassword: 'New password',
  bio: 'Bio',
  timezone: 'Timezone',
  status: 'Status',
  job: 'Job',
  city: 'City',
  country: 'Country',
  company: 'Company',
  noCompany: 'No company',
  email: 'Email',
  firstName: 'First name',
  lastName: 'Last name',
  name: 'Name',
  languages: 'Languages',
  passwordSet: 'Password set',
  passwordResetTokenSet: 'Password reset token set',
  lastSeen: 'Last seen',
  createdAt: 'Created at',
  updatedAt: 'Updated at',
  additionalInformation: 'Additional information',
}
