import cookieStorage from '/-/plugins/storage/cookies'

const TOKEN_NAME = 'gateway-auth'

async function getToken() {
  const token = cookieStorage.get(TOKEN_NAME)

  return token && JSON.parse(decodeURIComponent(token))
}

export function saveToken(token) {
  cookieStorage.set(TOKEN_NAME, JSON.stringify(token), token.expires_in)
}

export function removeToken() {
  cookieStorage.remove(TOKEN_NAME)
}

export function useAuthToken() {
  return {
    getToken,
    saveToken,
    removeToken,
  }
}
