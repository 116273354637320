export default {
  required: 'Required',
  email: 'Must be a valid email',
  min: 'The value must be at least 0:{length} characters long',
  max: 'The value may not be greater than 0:{length} characters',
  confirmed: 'Cannot confirm. Values do not match',
  url: 'This field should contain a URL',
  urlWithProtocol: 'This field should contain a URL with protocol',
  regex: 'This field is not valid',
  notLinkValidator: 'This field should not contain a URL',
  selected_options_limit: 'Maximum number of selected options: :limit',
}
