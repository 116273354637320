import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import calendar from 'dayjs/plugin/calendar'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/en'

export default {
  install: () => {
    dayjs.extend(LocalizedFormat)
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.extend(calendar)
    dayjs.extend(relativeTime)
  }
}
