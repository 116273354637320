export default {
  menu: 'Menu',
  required: 'Is required',
  settings: 'Settings',
  about: 'About',
  logout: 'Logout',
  email: 'Email address',
  name: 'Name',
  password: 'Password',
  repeat_password: 'Repeat password',
  new_password: 'New password',
  change_password: 'Change password',
  change_password_description: 'Enter your current password and then enter your new password',
  save: 'Save',
  help: 'Help',
  help_text_1: 'If you have any questions, please email us at',
  help_text_2: 'we will get back to you ASAP!',
  search: 'Search',
  search_placeholder: 'Search...',
  tags: 'Tags',
  company: 'Company',
  offer: 'Offer',
  look_for: 'Look For',
  all_variants: 'All Variants',
  regions: 'Regions',
  all_regions: 'All Regions',
  country: 'Country/Region',
  all_countries: 'All Countries',
  city: 'City',
  all_cities: 'All Cities',
  platform: 'Platform',
  stage: 'Stage',
  monetization: 'Monetization',
  description: 'Description',
  icon: 'Icon',
  cover: 'Cover',
  photo: 'Photo',
  photos: 'Photos',
  genre: 'Genre',
  engine: 'Engine',
  organization: 'Organization',
  call: 'Call',
  not_select: 'Not selected',
  select_prompt: 'Click to select',
  or: 'Or',
  got_it: 'Got It',
  delete: 'Delete',
  cancel: 'Cancel',
  filters: 'Filters',
  show: 'Show',
  error: 'Error',
  file_is_large: 'Uploaded file is too large',
  previous: 'Previous',
  next: 'Next',
  link: 'Link',
  links: 'Links',
  add_link: 'Add Link',
  copy_link: 'Copy Link',
  sort_by: 'Sort By',
  no_sort: 'Without Sort',
  registration_date: 'Registration Date',
  last_seen: 'Last Seen',
  over: 'Over',
  sure_to_leave: 'Are you sure you want to leave the page?',
  table_empty: 'Table is empty',
  table_empty_description: 'There is no data to display.',
  timezone: 'Timezone',
  back: 'Back',
  business_member: 'Business Member',
  no_matches_message: 'No matches found',
  clear_filters: 'Clear all filters',
  features: 'Attendee type',
  features_offline: 'Offline only',
  yes: 'Yes',
  no: 'No'
}
