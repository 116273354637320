import { configure, defineRule } from 'vee-validate'
import { localize } from '@vee-validate/i18n'
import {
  requiredValidator,
  emailValidator,
  urlValidator,
  templateValidator,
  urlWithProtocolValidator,
  notLinkValidator,
  minLengthValidator,
  maxLengthValidator,
  confirmedValidator,
  lowercaseValidator,
} from './rules'
import * as allRules from '@vee-validate/rules'
import langEn from '/~/languages/en/formErrors'

export const jsonValidator = (value: string) => {
  if (!value) {
    return true
  }
  try {
    JSON.parse(value)

    return true
  } catch (error: any) {
    return 'Must be a valid JSON'
  }
}

export default {
  install: () => {
    defineRule('required', requiredValidator)
    defineRule('email', emailValidator)
    defineRule('url', urlValidator)
    defineRule('urlWithProtocol', urlWithProtocolValidator)
    defineRule('notUrl', notLinkValidator)
    defineRule('min', minLengthValidator)
    defineRule('max', maxLengthValidator)
    defineRule('confirmed', confirmedValidator)
    defineRule('template', templateValidator)
    defineRule('minValue', allRules.min_value)
    defineRule('maxValue', allRules.max_value)
    defineRule('json', jsonValidator)
    defineRule('lowercase', lowercaseValidator)

    configure({
      generateMessage: localize({
        en: {
          messages: langEn,
        }
      }),
    })
  }
}
