export default {
  unlimited_chats: 'Unlimited Chats',
  unlimited_meetings: 'Unlimited Meetings',
  unlimited_video_call: 'Unlimited Video Call',
  limited_chats: 'Chats Available',
  limited_meetings: 'Meetings Available',
  limited_video_call: 'Video Calls Available',
  highlight: 'Highlight',
  game_poll: 'Game Poll',
  watch_lecture: 'Watch Lecture',
  jury: 'Jury',
  offline: 'Offline',
  pro_account: 'Pro'
}
